<template>
  <div class="dropdown">

    <label style="font-size: 12px; font-weight: bold">{{ $t('SelectProduct') }}
      <span v-if="showClear"
            @click="resetSelected()"
            class="span-clear cursor-pointer">{{ $t("Clear") }}</span>
    </label>
    <input v-if="Object.keys(selectedItem).length === 0" ref="dropdowninput"
           @focusout="removedFocus"
           @focusin="requestedFocus"
           @input="searchDebounce"
           v-model.trim="inputValue" class="dropdown-input" type="text" :placeholder="placeholder" />
    <div v-else @click="resetSelection" class="dropdown-selected">
      <p class="dropdown-item-selected"><strong>{{selectedItem.product_name}}</strong></p>
    </div>
    <div v-show="Object.keys(selectedItem).length === 0 && itemList.length > 0 && isFocused" class="dropdown-list">
      <div @click="selectItem(item)" v-for="item in itemList" :key="item.product_id" class="dropdown-item">
        <vs-col>
          <p class="dropdown-item-city"><strong>{{item.product_name}}</strong></p>
<!--          <p class="dropdown-item-country">{{ item.customer_id }}</p>-->
        </vs-col>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DynamicGiftCardsDropDown',
  data() {
    return {
      oldSelectedItem: {},
      inputValue: '',
      itemList: [],
      interval: null,
      isFocused: false,
    }
  },
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    selectedItem: {
      type: Object,
      default: {},
    },
    showClear: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
  },
  methods: {
    requestedFocus() {
      this.isFocused = true
      if (this.itemList.length === 0) {
        this.searchCustomers()
      }
    },
    removedFocus() {
      setTimeout(() => {
        this.$nextTick(() => {
          if (Object.keys(this.selectedItem).length === 0 && Object.keys(this.oldSelectedItem).length > 0) {
            this.selectedItem = this.oldSelectedItem
            this.inputValue = ''
            this.$emit('on-item-selected', this.selectedItem)
          }
          this.isFocused = false
        })
      }, 500)
    },
    resetSelection() {
      this.oldSelectedItem = this.selectedItem
      this.selectedItem = {}
      this.$nextTick(() => this.$refs.dropdowninput.focus())
      this.$emit('on-item-reset')
    },
    resetSelected() {
      this.$emit('on-item-selected', {})
    },
    selectItem(theItem) {
      this.selectedItem = theItem
      this.inputValue = ''
      this.$emit('on-item-selected', theItem)
    },
    searchDebounce() {
      clearTimeout(this.interval)
      this.interval = setTimeout(() => {
        this.searchCustomers()
      }, 500)
    },
    searchCustomers() {
      const filter = {
        product_type: 'gift card',
        total_items: 10,
      }
      this.$store.dispatch('products/fetchProducts', { filter, search: this.inputValue })
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
          this.itemList = data.content
        })
        .catch((error) => {
          this.$vs.loading.close()
          this.errorFetching = true
          let msg = ''
          try {
            msg = error.response.data.message
          } catch (err) {
            msg = ''
          }
          this.showErrorToast(msg)
        })
    },
    showErrorToast(msg) {
      this.$vs.notify({
        position: 'top-center',
        title: 'Error',
        text: msg,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger',
      })
    },
  },
}
</script>

<style>
.dropdown{
  position: relative;
  width: 100%;
  min-width: 200px;
  max-width: 400px;
  margin: 0 auto;
}
.dropdown-input, .dropdown-selected{
  width: 100%;
  padding: 10px 16px;
  border: 1px solid #00000059;
  background: #ffffff;
  line-height: 1.5em;
  outline: none;
  border-radius: 8px;
}
.dropdown-input:focus, .dropdown-selected:hover{
  background: #fff;
  border-color: #e2e8f0;
}
.dropdown-input::placeholder{
  opacity: 0.7;
}
.dropdown-selected{
  font-weight: bold;
  cursor: pointer;
}
.dropdown-list{
  position: absolute;
  width: 100%;
  max-height: 500px;
  margin-top: 4px;
  overflow-y: auto;
  background: #ffffff;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  z-index: 500;
}
.dropdown-item{
  display: flex;
  width: 100%;
  padding: 11px 16px;
  cursor: pointer;
}
.dropdown-item-selected {
  font-size: 14px;
  color: black;
}
.dropdown-item-city {
  font-size: 14px;
  color: black;
}
.dropdown-item-country {
  font-size: 12px;
  color: black;
}
.dropdown-item:hover{
  background: #edf2f7;
}
.dropdown-item-flag{
  max-width: 24px;
  max-height: 18px;
  margin: auto 12px auto 0px;
}
</style>
