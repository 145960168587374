<template>
<div>

  <breadcrumb-base :breadcrumb="breadcrumb" title="GiftCards">
    <template v-slot:right-end>
    </template>
  </breadcrumb-base>
  <div class="content-center">
    <div class="vx-row w-full mb-base c-center flex items-center justify-around">
      <div class="md:w-1/3 w-full flex">
        <vx-card >
          <div class="vx-row">
            <div class="vx-col w-full mt-10 mb-6 mx-3">
                <vs-col>
                  <h3 style="text-align: center; font-size: 18px; font-weight: 500;">{{ $t('EditGiftCard') }}</h3>
                  <div class="p-4"></div>

                  <h3 style="text-align: center; font-size: 24px; font-weight: bold;">
                    {{ selectedCard.product_name }} - {{ selectedCard.value  | germanNumberFormat }}
                  </h3>
                  <div class="p-4"></div>
                  <vs-input
                    style="margin: auto"
                    class="w-full custom-input-width"
                    v-model="selectedCardBatch"
                    :label="$t('BatchNo')">
                  </vs-input>
                  <div class="p-2"></div>
                  <dynamic-customers-drop-down
                    class="w-full"
                    :selected-item="selectedCardCustomer"
                    :placeholder="$t('SearchCustomer')"
                    @on-item-selected="onCardCustomerChanged($event)">
                  </dynamic-customers-drop-down>

                  <div class="vs-row flex justify-center mt-3" >
                    <vs-button class="ml-2 mt-3" color="primary" @click="updateGiftCardBatches()">{{ $t('Update') }}</vs-button>
                    <vs-button class="ml-4 mt-3" color="warning" @click="goBack">{{ $t('Close') }}</vs-button>
                    <vs-button class="ml-4 mt-3" color="danger" @click="deleteGiftCard()">{{ $t('Delete') }}</vs-button>
                  </div>
                </vs-col>
            </div>
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import DynamicCustomersDropDown from '@/views/pages/superadmin/customer/DynamicCustomersDropDown'
import DynamicGiftCardsDropDown from '../products/DynamicGiftCardsDropDown'


export default {
  name: 'UpdateGiftCard',
  components: { DynamicCustomersDropDown },
  data() {
    return {
      selectedCard: null,
      selectedCardCustomer: {},
      selectedCardBatch: '',
      breadcrumb: [
        { title: 'Home', i18n: 'Home', url: '/' },
        { title: 'GiftCards', i18n: 'GiftCards', url: '/admin/giftcards/' },
        { title: 'Edit GiftCard', i18n: 'EditGiftCard', active: true },
      ],
    }
  },
  props: {
    card: {
      type: String,
    }
  },
  computed: {
  },
  methods: {
    goBack() {
      this.$router.back()
    },
    onCardCustomerChanged(customer) {
      this.selectedCardCustomer = customer
    },
    updateGiftCardBatches() {
      if(Object.keys(this.selectedCardCustomer).length == 0) {
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: `Please select customer`,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'daner',
        })
        return
      }
      if(this.selectedCardBatch == '') {
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: `Please fill batch no`,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'daner',
        })
        return
      }
      const p = {
        "product_configuration_id": this.selectedCard.config_id,
        "gift_card_list": JSON.stringify([{
          "gift_card_id": this.selectedCard.gift_card_id,
          "customer_id": this.selectedCardCustomer.customer_id,
          "batch_no": this.selectedCardBatch
        }])
      }

      this.$vs.loading()
      return this.$store.dispatch('adminGiftCards/updateGiftCard', { payload: p })
        .then((data) => {
          this.$vs.loading.close()
          if (data.message_type == 1) {
            this.$vs.notify({
              position: 'top-center',
              title: data.message,
              text: `${data.success} Success`,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'success',
            })
            this.$router.back()
          } else {
            this.$vs.notify({
              position: 'top-center',
              title: 'Error',
              text: `${data.message}`,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'daner',
            })
          }
          this.$vs.loading.close()
          this.errorFetching = false
        })
        .catch((error) => {
          this.$vs.loading.close()
          let msg = ''
          try {
            msg = error.response.data.message || error.message
          } catch (err) {
            msg = err.message
          }
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: msg,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },deleteGiftCard() {

      const p = {
        "product_configuration_id": this.selectedCard.config_id,
        "gift_card_list": JSON.stringify([{
          "gift_card_id": this.selectedCard.gift_card_id,
          "delete": 1,
        }])
      }

      this.$vs.loading()
      return this.$store.dispatch('adminGiftCards/updateGiftCard', { payload: p })
        .then((data) => {
          this.$vs.loading.close()
          if (data.message_type == 1) {
            this.$vs.notify({
              position: 'top-center',
              title: 'Success',
              text: `GiftCard Deleted`,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'success',
            })
            this.$router.back()
          } else {
            this.$vs.notify({
              position: 'top-center',
              title: 'Error',
              text: `${data.message}`,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'daner',
            })
          }
          this.$vs.loading.close()
          this.errorFetching = false
        })
        .catch((error) => {
          this.$vs.loading.close()
          let msg = ''
          try {
            msg = error.response.data.message || error.message
          } catch (err) {
            msg = err.message
          }
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: msg,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
  },
  mounted() {
    try {
      this.selectedCard = JSON.parse(atob(this.card));
      this.selectedCardBatch = this.selectedCard.batch_no
      this.selectedCardCustomer = {
        customer_id: this.selectedCard.assigned_customer_id,
        name: this.selectedCard.assigned_to
      }
    } catch (e) {
      this.$router.push('/admin/giftcards/')
    }
  }
}
</script>

<style scoped>
.text-width{
  position: relative;
  width: 100%;
  min-width: 200px;
  max-width: 400px;
  margin: 0 auto;
}
.custom-input-width {
  min-width: 200px;
  max-width: 400px;
}
</style>
